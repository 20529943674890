import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../store/calendar/interfaces/event.interface';
import { Observable } from 'rxjs';
import { EventSearch } from '../store/calendar/interfaces/event-search.interface';

export interface Link {
  link: string;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private readonly baseApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {
    this.baseApiUrl = `${this.apiUrl}/calendar`;
  }

  getCalendarAuthLink(): Observable<Link> {
    return this.http.get<Link>(`${this.baseApiUrl}/auth-link`);
  }

  getToken(code: string): Observable<void> {
    return this.http.post<void>(`${this.baseApiUrl}/oauth2callback`, code);
  }

  getEvents(params: EventSearch, uid: string): Observable<{ pageToken: string; items: Event[] }> {
    return this.http.get<{ pageToken: string; items: Event[] }>(`${this.baseApiUrl}/employee/${uid}`, {
      params: <any>params,
    });
  }

  creatEvent(params: Event, uid: string): Observable<Event> {
    return this.http.post<Event>(`${this.baseApiUrl}/employee/${uid}`, params);
  }

  getEventById(id: string, uid: string): Observable<Event> {
    return this.http.get<Event>(`${this.baseApiUrl}/${'employee'}/${uid}/${id}`);
  }

  updateEvent(id: string, params: Event, uid: string): Observable<Event> {
    return this.http.put<Event>(`${this.baseApiUrl}/employee/${uid}/${id}`, { ...params });
  }

  deleteEvent(id: string, uid: string): Observable<void> {
    return this.http.delete<void>(`${this.baseApiUrl}/employee/${uid}/${id}`);
  }

  getUserCalendar(params: EventSearch, uid: string): Observable<{ pageToken: string; items: Event[] }> {
    return this.http.get<{ pageToken: string; items: Event[] }>(`${this.baseApiUrl}/owner`, {
      params: { ...params, userUid: uid },
    });
  }

  creatCalendarEvent(params: Event, userUid: string) {
    return this.http.post(`${this.baseApiUrl}/owner`, {
      ...params,
      userUid,
    });
  }

  saveCalendarEvent(params: Event, id: string, userUid: string) {
    return this.http.put(`${this.baseApiUrl}/owner/event/${id}`, {
      ...params,
      userUid,
    });
  }

  deleteCalendarEvent(id: string, userUid: string): Observable<void> {
    return this.http.delete<void>(`${this.baseApiUrl}/owner/event/${id}`, {
      params: { userUid },
    });
  }

  getUserEventById(id: string, userUid?: string): Observable<Event> {
    return this.http.get<Event>(`${this.baseApiUrl}/owner/event/${id}`, { params: { userUid } });
  }

  getPortalUsers(search: string): Observable<Array<{ uid: string; name: string }>> {
    return this.http.get<Array<{ uid: string; name: string }>>(`${this.baseApiUrl}/portal-users`, { params: { search } });
  }

  getEmployeeListForCalendar(search: string): Observable<Array<{ uid: string; name: string }>> {
    return this.http.get<Array<{ uid: string; name: string }>>(`${this.baseApiUrl}/portal-employees`, { params: { search } });
  }

  getPatientListForCalendar(search: string): Observable<Array<{ id: string; name: string }>> {
    return this.http.get<Array<{ id: string; name: string }>>(`${this.baseApiUrl}/portal-patients`, {
      params: {
        search,
        onlyActive: true,
      },
    });
  }
}
