import { CalendarService } from '../../services/calendar.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AddUserCalendar,
  ClearEvents,
  CreateEvent,
  CreateEventCalendarPage,
  DeleteEvent,
  DeleteEventCalendar,
  EditEvent,
  GetAuthLink,
  GetEmployeeForCalendar,
  GetEventById,
  GetEvents,
  GetPatientForCalendar,
  GetPortalUsers,
  GetToken,
  GetTokenSuccess,
  GetUserCalendarPage,
  GetGroupOfUsersCalendarPage,
  ModalEvent,
  RemoveUserCalendarPage,
  SaveEventCalendar,
  SetEventSuccess,
  SetSelectedEvent,
  TypeModalEvent,
  ClearSetSelectedEvent,
} from './calendar.action';
import { Event } from './interfaces/event.interface';
import { finalize, tap } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { IdNameObject } from '../../../shared/models';

export interface CalendarStateModel {
  events: Event[];
  stateModal: TypeModalEvent;
  activeEventModal: Event;
  authLink: string;
  pageToken: string;
  selectedEvent: Event;
  pending: boolean;
  portalUsers: Array<{ uid: string; name: string }>;
  employeesList: Array<{ uid: string; name: string }>;
  patientList: IdNameObject[];
  triggerGetEvents: boolean;
}

const defaultState: CalendarStateModel = {
  events: [],
  stateModal: TypeModalEvent.Close,
  activeEventModal: null,
  authLink: '',
  pageToken: '',
  selectedEvent: null,
  pending: true,
  portalUsers: [],
  employeesList: [],
  patientList: [],
  triggerGetEvents: false,
};

@State({
  name: 'calendarState',
  defaults: defaultState,
})
@Injectable()
export class CalendarState {
  constructor(
    private service: CalendarService,
    private store: Store,
  ) {}

  @Selector()
  static pending(state): string {
    return state.pending;
  }

  @Selector()
  static authLink(state): string {
    return state.authLink;
  }

  @Selector()
  static selectedEvent(state): string {
    return state.selectedEvent;
  }

  @Selector()
  static events(state): Event[] {
    return state.events;
  }

  @Selector()
  static pageToken(state): Event[] {
    return state.pageToken;
  }

  @Selector()
  static stateModal(state): Observable<TypeModalEvent> {
    return state.stateModal;
  }

  @Selector()
  static activeEventModal(state): Observable<TypeModalEvent> {
    return state.activeEventModal;
  }

  @Selector()
  static portalUsers(state) {
    return state.portalUsers.map(i => ({ ...i, checked: false }));
  }

  @Selector()
  static triggerGetEvents(state) {
    return state.triggerGetEvents;
  }

  @Action(GetAuthLink)
  getAuthLink(ctx: StateContext<CalendarStateModel>) {
    this.setPending(ctx, true);
    return this.service.getCalendarAuthLink().pipe(
      tap(authLink => {
        ctx.patchState({
          authLink: authLink.link,
        });
      }),
      finalize(() => {
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(GetEvents)
  getEvents(ctx: StateContext<CalendarStateModel>, { params, uid }: GetEvents) {
    let allItems = [];
    const pageTokens = [];
    const fetchPage = (p, id, pageToken = null) => {
      return this.service.getEvents({ ...p, pageToken }, id).pipe(
        tap(({ items, pageToken: newPageToken }) => {
          const processedItems = items.map(item => ({
            ...item,
            summary: item.summary === null ? 'No title' : item.summary,
          }));
          allItems = [...allItems, ...processedItems];
          if (newPageToken) {
            pageTokens.push({
              pageToken: newPageToken,
              uid: id,
            });
          }
          ctx.patchState({
            events: [...allItems],
            pageToken: newPageToken,
          });
        }),
        switchMap(({ pageToken: nextPageToken }) => {
          if (nextPageToken) {
            const token = pageTokens.find(t => t.pageToken === nextPageToken);
            if (token) {
              return fetchPage(p, token.uid, nextPageToken);
            } else {
              return EMPTY;
            }
          } else {
            return EMPTY;
          }
        }),
      );
    };

    this.setPending(ctx, true);

    return fetchPage(params, uid).pipe(
      finalize(() => {
        ctx.patchState({
          triggerGetEvents: false,
        });
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(CreateEvent)
  createEvent(ctx: StateContext<CalendarStateModel>, { event, uid }) {
    const state: CalendarStateModel = ctx.getState();
    return this.service.creatEvent(event, uid).pipe(
      tap(newEvent => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: [...state.events, newEvent],
          selectedEvent: null,
        });
      }),
    );
  }

  @Action(GetEventById)
  getEventById(ctx: StateContext<CalendarStateModel>, { id, uid }: GetEventById) {
    return this.service.getEventById(id, uid);
  }

  @Action(EditEvent)
  editEvent(ctx: StateContext<CalendarStateModel>, { id, event, uid }) {
    const state: CalendarStateModel = ctx.getState();
    return this.service.updateEvent(id, event, uid).pipe(
      tap((newEvent: Event) => {
        this.store.dispatch(new SetEventSuccess());
        ctx.patchState({
          events: state.events.map((e: Event): Event => (e.id === id ? newEvent : e)),
          selectedEvent: null,
        });
      }),
    );
  }

  @Action(DeleteEvent)
  deleteEvent(ctx: StateContext<CalendarStateModel>, { id, uid }: DeleteEvent): Observable<void> {
    const state: CalendarStateModel = ctx.getState();
    return this.service.deleteEvent(id, uid).pipe(
      tap(() =>
        ctx.patchState({
          events: state.events.filter((event: Event): boolean => event.id !== id),
        }),
      ),
      finalize(() => {
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(ModalEvent)
  modalEvent(ctx: StateContext<CalendarStateModel>, { stateModal, id }: ModalEvent): void {
    const state: CalendarStateModel = ctx.getState();

    ctx.patchState({
      stateModal,
      activeEventModal: id ? state.events.find((event: Event): boolean => event.id === id) : null,
    });
  }

  @Action(GetToken)
  getToken(ctx: StateContext<CalendarStateModel>, code: string): Observable<void> {
    return this.service.getToken(code).pipe(finalize(() => this.store.dispatch(new GetTokenSuccess())));
  }

  @Action(SetSelectedEvent)
  setSelectedEvent(ctx: StateContext<CalendarStateModel>, { event, type }: SetSelectedEvent) {
    ctx.patchState({ selectedEvent: null });
    if (type === 'employee') {
      return this.service.getEventById(event.id, event.ownerId).pipe(
        tap(value => {
          ctx.patchState({ selectedEvent: value });
        }),
      );
    } else {
      return this.service.getUserEventById(event.id, event.ownerId).pipe(
        tap(value => {
          ctx.patchState({ selectedEvent: value });
        }),
      );
    }
  }

  @Action(ClearSetSelectedEvent)
  clearSetSelectedEvent(ctx: StateContext<CalendarStateModel>) {
    ctx.patchState({
      selectedEvent: null,
    });
  }

  @Action(ClearEvents)
  clearEvents(ctx: StateContext<CalendarStateModel>): void {
    ctx.patchState({
      events: [],
    });
  }

  @Action(GetPortalUsers)
  getPortalUsers(ctx: StateContext<CalendarStateModel>, { search }: GetPortalUsers) {
    return this.service.getPortalUsers(search ? search : '').pipe(
      tap(value => {
        ctx.patchState({
          portalUsers: value,
        });
      }),
    );
  }

  @Action(GetGroupOfUsersCalendarPage)
  getUsersGroupCalendar(ctx: StateContext<CalendarStateModel>, { params, users }: GetGroupOfUsersCalendarPage) {
    let allItems = [];
    const pageTokens = [];
    const fetchUserCalendars = (userParams, uids) => {
      return forkJoin(uids.map(uid => this.service.getUserCalendar(userParams, uid))).pipe(
        tap((values: Array<{ pageToken: string; items: Event[] }>) => {
          values.forEach(({ pageToken, items }) => {
            const processedItems = items.map(item => {
              item.summary = item.summary ?? 'No title';
              return item;
            });
            allItems = [...allItems, ...processedItems];
            if (pageToken) {
              pageTokens.push({ pageToken, uid: items[0].ownerId });
            }
          });
          ctx.patchState({ events: [...allItems] });
        }),
        switchMap(() => (pageTokens.length ? fetchNextPages(userParams, pageTokens) : EMPTY)),
      );
    };
    const fetchNextPages = (userParams, tokens) => {
      const nextPageTokens = [];
      return forkJoin(tokens.map(({ pageToken, uid }) => this.service.getUserCalendar({ ...userParams, pageToken }, uid))).pipe(
        tap((values: Array<{ pageToken: string; items: Event[] }>) => {
          values.forEach(({ pageToken, items }) => {
            const processedItems = items.map(item => {
              item.summary = item.summary ?? 'No title';
              return item;
            });
            allItems = [...allItems, ...processedItems];
            if (pageToken) {
              nextPageTokens.push({ pageToken, uid: items[0].ownerId });
            }
          });
          ctx.patchState({ events: [...allItems] });
        }),
        switchMap(() => (nextPageTokens.length ? fetchNextPages(userParams, nextPageTokens) : EMPTY)),
      );
    };
    this.setPending(ctx, true);
    return fetchUserCalendars(
      params,
      users.map(user => user.uid),
    ).pipe(
      finalize(() => {
        ctx.patchState({ triggerGetEvents: false });
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(AddUserCalendar)
  addUserCalendar(ctx: StateContext<CalendarStateModel>, { params, uid }: AddUserCalendar) {
    this.setPending(ctx, true);
    let allItems = [];
    const pageTokens = [];
    const fetchUserCalendar = (p, userId, token = null) => {
      return this.service.getUserCalendar({ ...p, pageToken: token }, userId).pipe(
        tap(({ items, pageToken: nextToken }) => {
          const processedItems = items.map(item => {
            item.summary = item.summary === null ? 'No title' : item.summary;
            return item;
          });
          allItems = [...allItems, ...processedItems];
          const state: CalendarStateModel = ctx.getState();
          const filterEvents = state.events.filter(i => i.ownerId !== userId);
          ctx.patchState({
            events: [...filterEvents, ...allItems],
          });
          if (nextToken) {
            pageTokens.push(nextToken);
          }
        }),
        switchMap(() => {
          if (pageTokens.length) {
            const nextPageToken = pageTokens.shift();
            return fetchUserCalendar(p, userId, nextPageToken);
          } else {
            return EMPTY;
          }
        }),
      );
    };

    return fetchUserCalendar(params, uid).pipe(
      finalize(() => {
        ctx.patchState({
          triggerGetEvents: false,
        });
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(RemoveUserCalendarPage)
  removeUserAdmissionCalendar(ctx: StateContext<CalendarStateModel>, { uid }: RemoveUserCalendarPage) {
    const state = ctx.getState();
    ctx.patchState({
      events: state.events.filter(i => i.ownerId !== uid),
    });
  }

  @Action(GetUserCalendarPage)
  getUserCalendarPage(ctx: StateContext<CalendarStateModel>, { params, uid }: GetUserCalendarPage) {
    let allItems = [];
    const fetchPage = (p, id, pageToken = null) => {
      return this.service.getUserCalendar({ ...p, pageToken }, id).pipe(
        tap(({ items }) => {
          const processedItems = items.map(item => {
            item.summary = item.summary === null ? 'No title' : item.summary;
            return item;
          });
          allItems = [...allItems, ...processedItems];
          ctx.patchState({
            events: [...allItems],
          });
        }),
        switchMap(({ pageToken: nextPageToken }) => {
          return nextPageToken ? fetchPage(p, id, nextPageToken) : EMPTY;
        }),
      );
    };

    return fetchPage(params, uid).pipe(
      finalize(() => {
        ctx.patchState({
          triggerGetEvents: false,
        });
        this.setPending(ctx, false);
      }),
    );
  }

  @Action(CreateEventCalendarPage)
  createEventAdmissionCalendar(ctx: StateContext<CalendarStateModel>, { params, uid }: CreateEventCalendarPage) {
    return this.service.creatCalendarEvent(params, uid).pipe(
      tap(() => {
        ctx.patchState({
          triggerGetEvents: true,
        });
      }),
    );
  }

  @Action(SaveEventCalendar)
  saveEventCalendar(ctx: StateContext<CalendarStateModel>, { params, uid, idEvent }: SaveEventCalendar) {
    return this.service.saveCalendarEvent(params, idEvent, uid).pipe(
      tap(() => {
        ctx.patchState({
          triggerGetEvents: true,
          selectedEvent: null,
        });
      }),
    );
  }

  @Action(DeleteEventCalendar)
  deleteEventCalendar(ctx: StateContext<CalendarStateModel>, { idEvent, uid }: DeleteEventCalendar) {
    return this.service.deleteCalendarEvent(idEvent, uid).pipe(
      tap(() => {
        ctx.patchState({
          triggerGetEvents: true,
          selectedEvent: null,
        });
      }),
    );
  }

  @Action(GetEmployeeForCalendar)
  getEmployeeForCalendar(ctx: StateContext<CalendarStateModel>, { search }: GetEmployeeForCalendar) {
    return this.service.getEmployeeListForCalendar(search).pipe(
      tap(value => {
        ctx.patchState({
          employeesList: value,
        });
      }),
    );
  }

  @Action(GetPatientForCalendar)
  getPatientForCalendarForCalendar(ctx: StateContext<CalendarStateModel>, { search }: GetPatientForCalendar) {
    return this.service.getPatientListForCalendar(search).pipe(
      tap(value => {
        ctx.patchState({
          patientList: value,
        });
      }),
    );
  }

  private setPending(ctx: StateContext<CalendarStateModel>, value: boolean) {
    ctx.patchState({
      pending: value,
    });
  }
}
